<template>
    <div class="InternalData_I">
        <div class="InternalLeft">
            <div class="Data">共享资料</div>
            <div class="Data1" @click="FolderClick(0)">
                <div>
                    <span><img src="../../../assets/img/immm.png" alt=""></span>
                    <span>/</span>
                </div>
            </div>
            <div class="Data1" v-for="item in FolderList" :key="item.id" >
                <div @click="FolderClick(item.id)">
                    <span><img src="../../../assets/img/immm.png" alt=""></span>
                    <span>{{item.name}}</span>
                </div>
                <el-dropdown trigger="click">
                    <i class="el-icon-more"></i>
                    <template #dropdown>
                      <el-dropdown-menu class="menu">
                        <el-dropdown-item class="menu__item" @click="HandleEdit(item)">编辑</el-dropdown-item>
                        <el-dropdown-item class="menu__item" @click="HandleDel(item)">删除</el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
        <div class="InternalRight">
            <div class="TablesADD">
                <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建资料</div>
                <el-upload
                  :action="upload"
                  :headers="heads"
                  :limit="1"
                  :data = "pid_obj"
                  :multiple="false"
                  :on-success="UP_Success3"
                  :on-remove="fileRemove">
                  <span style="color:#595959">上传</span>
                </el-upload>
                <div class="Inter">
                    <div @click="isImgs = false" :class="isImgs == false ?'divActive' :''"><img src="../../../assets/img/lef_Ch.png" alt=""></div>
                    <div @click="isImgs = true" :class="isImgs == true ?'divActive' :''"><img src="../../../assets/img/rig_Ch.png.png" alt=""></div>
                </div>
            </div>
            <el-table
                v-if="isImgs == false"
                :data="tableData"
                class="table"
                ref="multipleTable"
                header-cell-class-name="table-header"
                @selection-change="handleSelectionChange">
                <el-table-column label="公告名称" width="80">
                    <template #default="scope" >
                      <img class="Toper" v-if="scope.row.type == 'folder'" @click="AuAxAb(scope.row.id)" src="../../../assets/img/a25ce5046f438457163a9e15c5d1157.png" alt="">
                      <img class="Toper" v-else-if="scope.row.format == 'doc' || scope.row.format == 'docx'" src="../../../assets/img/word.png" alt="">
                      <img class="Toper" v-else-if="scope.row.format == 'pdf'" src="../../../assets/img/pdf.png" alt="">
                      <img class="Toper" v-else-if="scope.row.format == 'ppt' || scope.row.format == 'pptx'" src="../../../assets/img/ppt.png" alt="">
                      <img class="Toper" v-else-if="scope.row.format == 'xls' || scope.row.format == 'xlsx'" src="../../../assets/img/xlsx.png" alt="">
                      <img class="Toper" v-else-if="scope.row.format == 'zip' || scope.row.format == 'rar'" src="../../../assets/img/zzzip.png" alt="">
                      <img class="Toper" v-else-if="scope.row.format == 'jpg' || scope.row.format == 'png' || scope.row.format == 'bmp'|| scope.row.format == 'jpeg' || scope.row.format == 'gif'" src="../../../assets/img/jpg.png" alt="">
                      <img class="Toper" v-else src="../../../assets/img/qita.png" alt="">
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="" width="480">
                  <template #default="scope">
                    <div style="cursor: pointer;" v-if="scope.row.type == 'folder'" @click="AuAxAb(scope.row.id)">{{scope.row.name}}</div>
                    <div v-if="scope.row.type != 'folder'">{{scope.row.name}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="staff.realname" label="更新者" width="120"></el-table-column>
                <el-table-column prop="filesize" label="大小" width="120"></el-table-column>
                <el-table-column prop="createtime" label="最近更新" width="150"></el-table-column>
                <el-table-column label="操作" align="center" >
                  <template #default="scope" >
                      <div class="el_butto" >
                        <el-button type="text" @click="handles(scope.row)" v-if="scope.row.type != 'folder'">查看</el-button>
                      </div>
                      <div class="el_butto" @click.stop  v-if="TextMoMo('workbench:disk:folder:edit')">
                        <el-button type="text" @click="bianji(scope.row)">修改名称</el-button>
                      </div>
                      <div class="el_butto rere" >
                        <el-button v-if="TextMoMo('workbench:disk:folder:del')" type="text" class="red" @click="HanDel(scope.row)">删除</el-button>
                      </div>
                  </template>
                </el-table-column>
            </el-table>   

            <div class="InternaImgs" v-else>
                <div class="InternaImgsLi" v-for="(item,i) in tableData" :key="i" @click="handles(item)">
                    <img v-if="item.type == 'folder'" src="../../../assets/img/imm2.png" alt="" @click.stop="AuAxAb(item.id)">
                    <img v-else-if="item.format == 'doc' || item.format == 'docx'"  src="../../../assets/img/word2.png" alt="">
                    <img v-else-if="item.format == 'pdf'" src="../../../assets/img/pdf2.png" alt="">
                    <img class="Toper" v-else-if="item.format == 'ppt' || item.format == 'pptx'" src="../../../assets/img/ppt2.png" alt="">
                    <img class="Toper" v-else-if="item.format == 'xls' || item.format == 'xlsx'" src="../../../assets/img/xlsx2.png" alt="">
                    <img class="Toper" v-else-if="item.format == 'zip' || item.format == 'rar'" src="../../../assets/img/zip2.png" alt="">
                    <img class="Toper" v-else-if="item.format == 'jpg' || item.format == 'png' || item.format == 'bmp'|| item.format == 'jpeg' || item.format == 'gif'" :src="item.file_url" alt="">
                    <img class="Toper" v-else src="../../../assets/img/qita2.png" alt="">
                    <span>{{item.name}}</span>
                </div>
            </div>
        </div>
        <el-dialog :title="titleMath" v-model="editVisible1" width="24%">
        <el-form class="EditNewForm" style="padding: 0px;" >
          <el-form-item class="EditNewFormBtns" v-if="isPid == 1">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>输入文件夹名称：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="pidName" placeholder="请输入文件夹名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" v-if="isPid == 2">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>公告名称：</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="pidName" placeholder="请输入公告名称"></el-input>
            </div>
          </el-form-item>
          
          <el-form-item class="EditNewFormBtns" style="margin-top:20px">
            <div v-if="editPanel == 1" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
            <div v-if="editPanel == 2" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
            <div v-if="editPanel == 3" class="FormBtnsQuery" @click="BtnEdit1()">保存</div>
            <div class="FormBtnsClose" @click="Colse()">取消</div>
          </el-form-item>
        </el-form>
      </el-dialog>

    </div>
</template>

<script>
import axios from 'axios'
import TextMoth from "../../../components/utils.vue";
export default {
    data(){
        return{
            upload:'https://rest-api.duxin365.com/admin/workbench/disk/upload',
            heads:{token:''}, 
            FolderList:[],
            tableData:[],
            page: {currentPage: 1,pageSize: 8},
            folder_id:'',
            pidName:'',
            titleMath:'',
            editPanel:'',
            pid:0,
            isPid:'',
            editId:'',
            isImgs:false,
            editVisible1:false,
        }
    },
    mounted(){
        let token = window.localStorage.getItem("accessToken")
        this.heads.token = token
        this.loadTable()
        this.loadTable2()
    },
    methods:{
        loadTable() {  // 获取列表数据
            var that = this
            axios({
              method: 'post',
              url: '/admin/workbench/disk/folderList',
              data: {},
            }).then(function (res) {
              that.FolderList = res.data.data.folderList
            })
        },
        loadTable2() {  // 获取列表数据
            var that = this
            axios({
              method: 'post',
              url: '/admin/workbench/disk/fileList',
              data: {
                folder_id:that.folder_id
              }
            }).then(function (res) {
              that.tableData = res.data.data.fileList
            })
        },
        UP_Success3() {            
            var that = this
            axios({
                method: 'post',
                url: '/admin/workbench/disk/fileList',
                data: {
                  folder_id: that.pid
                },
            }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {
                  that.tableData = res.data.data.fileList
                }
            })  
        },
        showAdd(){
            this.editVisible1 = true
            this.titleMath = '新建文件夹'
            this.editPanel = 1
            this.isPid = 1
        },
        bianji(data){
            this.editVisible1 = true
            this.titleMath = '修改公告名称'
            this.editPanel = 3
            this.isPid = 2
            this.editId = data.id
            this.pidName = data.name
        },
        BtnAdd(){
            var that = this
            axios({
              method: 'post',
              url: '/admin/workbench/disk/addFolder',
              data: {
                row:{
                    pid:that.pid,
                    name: that.pidName
                }
              },
            }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                that.editVisible1 = false
                that.pidName = ''
                that.loadTable();
                that.loadTable2();
              }
            }) 
        },
        HandleEdit(data){
            this.titleMath = '修改文件夹'
            this.editPanel = 2
            this.editId = data.id
            this.editVisible1 = true
            this.pidName = data.name
        },
        BtnEdit(){
            var that = this
            axios({
              method: 'put',
              url: '/admin/workbench/disk/editFolder',
              data: {
                id:that.editId,
                name:that.pidName
              },
            }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                that.editVisible1 = false
                that.pidName = ''
                that.loadTable();
                that.loadTable2();
                
              }
            }) 
        },
        BtnEdit1(){
            var that = this
            axios({
              method: 'put',
              url: '/admin/workbench/disk/editFile',
              data: {
                id:that.editId,
                name:that.pidName
              },
            }).then(function (res) {
              if (res.data.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                that.$message.success(res.data.msg);
                that.editVisible1 = false
                that.pidName = ''
                that.loadTable2();
                
              }
            }) 
        },
        HandleDel(data){
            var that = this
            that.$confirm("确定要删除吗？", "提示", {
              type: "primary"
            }).then(() => {
              axios({
                method: 'DELETE',
                url: '/admin/workbench/disk/delFolder',
                data: {
                  id: data.id
                }
              }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {
                    that.$message.success(res.data.msg);
                    that.loadTable();
                    that.loadTable2()
                }
              })
            })
        },
        HanDel(data){
            var that = this
            that.$confirm("确定要删除吗？", "提示", {
              type: "primary"
            }).then(() => {
              axios({
                method: 'DELETE',
                url: '/admin/workbench/disk/delFile',
                data: {
                  id: data.id
                }
              }).then(function (res) {
                if (res.data.code == 0) {
                  that.$message.warning(res.data.msg);
                } else {
                    that.$message.success(res.data.msg);
                    that.loadTable2();
                }
              })
            })
        },
        Colse(){
            this.editVisible1 = false
        },  
        FolderClick(data){
            this.folder_id = data
            this.loadTable2()
        },
        handles(row){
            window.open(row.file_url, '_blank');
        },
        TextMoMo(e) {
            var arr = TextMoth.getData(e)
            return arr  
        }
        
    }

}
</script>

<style scoped>
.InternalData_I{
    display: flex;
    justify-content: space-between;
    margin: 15px 24px 0;
}
.InternalLeft{
    width: 18%;
    background-color: #fff;
    border-radius: 6px;
    padding: 1.5%;
}
.InternalRight{
    width: 75%;
    background-color: #fff;
    border-radius: 6px;
    padding: 1.5%;
}
.InternalLeft div{
    cursor: pointer;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #595959;
}
.Data{
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #262626;
    margin-bottom: 16px;
}
.Data1{
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}
.Data1 img{
    width: 13px;
    height: 13px;
    float: left;
    margin-right: 8px;
    margin-top: 5px;
    overflow: hidden;
}
.el-icon-more{
    cursor: pointer;
    margin-left: 8px;
    position: relative;
    transform: rotate(90deg);
}
.menu{
    width: 70px !important;
    margin-top: 0;
}
.menu__item{
    margin-bottom: 0;
}
.Inter{
    display: flex;
    justify-content: space-between;
    width: 66px;
    height: 32px;
    line-height: 32px;
    border-radius: 6px;
    overflow: hidden;
    border: 1px solid #D9D9D9;
}
.Inter div{
    width: 50%;
    text-align: center;
    cursor: pointer;
}
.divActive{
    background-color: #D9D9D9;
}
.Inter img{
    width: 18px;
    height: 18px;
    margin-top: 7px;
}
.InternalData_I /deep/ .el-upload--text{
  float: right ;
  border: 0;
  width: 50px;
  height: 34px;
  line-height: 34px;
  margin-right: 24px;
  border: 1px solid #D9D9D9;
}
.InternaImgs{
    display: flex;
    flex-wrap: wrap;
}
.InternaImgsLi{
    width: 90px;
    text-align: center;
    margin-right: 47px;
    margin-bottom: 20px;
}   
.InternaImgsLi img{
    width: 80px;
    height: 80px;
    margin-bottom: 8px;
}
</style>