
<template>
  <div class="Family_I">
    <div class="headNavs">
      <div style="display:flex">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect">
          <el-menu-item index="1">企业公告</el-menu-item>
          <el-menu-item index="2">内部资料</el-menu-item>
          <el-menu-item index="3">协同办公</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">说明：用于内部通知，使用场景如：通知、求助、任务、因为数据内部管理数据所以安全性比企业微信群更可靠</div>
    </div>

    <InternalData v-if="isShow == 2"></InternalData>
    <Coordination v-if="isShow == 3"></Coordination>  <!-- 协同办公 -->
    <div v-if="isShow == 1">
      <div class="TablesList">
        <div class="TablesADD">
          <div style="width:200px;margin-right:24px">
            <el-select v-model="search.type" placeholder="请选择" @change="Chang_ses">
              <el-option v-for="item in MMD" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select> 
          </div>
          <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建汇报</div>
          <div class="TableIpt"> 
            <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入提交人">
            <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
          </div>
        </div>
        <el-table
          :data="tableData"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handhange">
          <el-table-column align="center" label="公告名称">
             <template #default="scope" >
               <img class="Toper" :src="scope.row.staff.full_avatar" alt="">
             </template>
          </el-table-column>
          <el-table-column align="center"  label="标题" prop="title" ></el-table-column>
          <el-table-column align="center" prop="staff.realname" label="发布人" ></el-table-column>
          <el-table-column align="center" prop="createtime" label="发布时间" ></el-table-column>
          <el-table-column align="center" prop="" label="阅读状态" v-if="search.type == 10">
            <template #default="scope" >  
              <div v-if="scope.row.is_read == 0"><span class="Redad1"></span>未读</div>
              <div v-if="scope.row.is_read == 1"><span class="Redad"></span>已读</div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="" label="阅读状态" v-if="search.type == 20">
            <template #default="scope" >
              <span>{{ {hidden : '未读' ,normal: '已读' }[scope.row.status || 0]}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" v-if="search.type == 20">
            <template #default="scope" >
                <div class="el_butto" >
                  <el-button
                  type="text"
                  v-if="TextMoMo('workbench:notice:read')"
                  @click="HandleSee(scope.row)"
                  >查看</el-button>
                </div>
                <div class="el_butto" >
                  <el-button
                  type="text"
                  v-if="TextMoMo('workbench:notice:edit')"
                  @click="HandleEdit(scope.row)"
                  >编辑</el-button>
                </div>
              <div class="el_butto rere" >
                  <el-button
                  type="text"
                  class="red"
                  v-if="TextMoMo('workbench:notice:del')"
                  @click="HandleDel(scope.row)"
                  >删除</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" v-if="search.type == 10">
            <template #default="scope">
              <div class="el_butto" >
                <el-button
                  type="text"
                  @click="HandleSee(scope.row)"
                  v-if="TextMoMo('workbench:notice:read')"
                >查看
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="page.currentPage"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </div>

      <el-dialog :title="titleMath" v-model="editVisible1" width="34%">
        <el-form class="EditNewForm" label-width="70px" style="padding: 0px;" >
          <el-form-item label="标题" class="EditNewFormItem">
            <el-input v-model="Row.title" placeholder="请填写标题" :disabled="control"></el-input>
          </el-form-item>
          <el-form-item label="作者" class="EditNewFormItem">
            <el-input v-model="Row.author" placeholder="请填写作者" :disabled="control"></el-input>
          </el-form-item>
          <el-form-item label="可见员工" class="EditNewFormBtns">
            <el-select v-model="Row.send_ids" multiple placeholder="请选择可见员工" :disabled="control">
              <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <div id="demo1"></div>
          <el-form-item class="EditNewFormBtns" style="margin-top:20px">
            <div v-if="editPanel == 1" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
            <div v-if="editPanel == 2" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
            <div class="FormBtnsClose" @click="Colse()">取消</div>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue"; 
import wangEditor from 'wangeditor'
import InternalData from './components/InternalData.vue'
import Coordination from './components/Coordination.vue'
export default {
  name: "basetable",
  components:{
    InternalData,Coordination
  },
  data() {
    return {
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      MMD:[{id:10,name:'我收到的'},{id:20,name:'我发出的'}],
      page: {currentPage: 1,pageSize: 10},
      search:{
        type: 20,
        keyword:'',
      },
      Row:{
        title:'',
        author:'',
        content:'',
        createtime:'',
        send_ids:[]
      },
      activeIndex: 1,
      isShow: 1,
      total:'',
      wangE:0,
      Eattr:'',
      editId:'',
      editPanel:'',
      editorData:'',
      titleMath:'',
      editor:'',
      tableData:[],
      StaFFList:[],
      editVisible1:false,
      control:false
    };
  },
  mounted() {
    let type = this.$route.query.isShow;
    if(type == 1){
      this.isShow = 1
      this.activeIndex = 1
    }else if(type == 2){
      this.isShow = 2
      this.activeIndex = 2
    }else if(type == 3){
      this.isShow = 3
      this.activeIndex = 3
    }else if(type == 'undefined'){
      this.isShow = 1
      this.activeIndex = 1
    }
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable()
    that.getStaff()
  },
  
  created(){
    var that = this
    
  },
  methods: {
    loadTable() {  // 获取列表数据
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/notice/index',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    showAdd(){
      this.editVisible1 = true
      this.titleMath = '发布公告'
      this.editPanel = 1
      this.init()
    },
    BtnAdd(){
      var that = this
      that.Row.content = that.editorData
      var timestamp = Date.parse(new Date());
      var time = that.formatDate(timestamp)
      that.Row.createtime = time
      axios({
        method: 'post',
        url: '/admin/workbench/notice/add',
        data: {
          row: that.Row
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.editVisible1 = false
          that.loadTable();
        }
      })
    },
    HandleEdit(data){
      var that = this
      that.editVisible1 = true
      that.titleMath = '编辑公告'
      that.editPanel = 2
      that.editId = data.id
      that.init()
      axios.get('/admin/workbench/notice/read', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        that.editorData = res.data.data.row.content
        that.Row.title = res.data.data.row.title
        that.Row.author = res.data.data.row.author
        that.Row.createtime = res.data.data.row.createtime
        that.Row.send_ids = res.data.data.send_ids
      }) 
    },
    HandleSee(data){
      var that = this
      that.editVisible1 = true
      that.control = true
      that.titleMath = '查看公告'
      that.editPanel = 3
      that.init()
      axios.get('/admin/workbench/notice/read', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        that.editorData = res.data.data.row.content
        that.Row.title = res.data.data.row.title
        that.Row.author = res.data.data.row.author
        that.Row.createtime = res.data.data.row.createtime
        that.Row.send_ids = res.data.data.send_ids
      }) 
    },
    HandleDel(data){
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/workbench/notice/del',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    BtnEdit(){
      var that = this
      that.Row.content = that.editorData
      axios({
        method: 'put',
        url: '/admin/workbench/notice/edit',
        data: {
          id: that.editId,
          row: that.Row
        },
      }).then(function (res) {
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.editVisible1 = false
          that.loadTable();
        }
      })
    },
    Chang_ses(e){
      this.search.type = e
      this.loadTable()
    },
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    getStaff(){
      var that = this
      axios.get('/admin/ajax/staffList',{   // 全部员工列表
      }).then(function (res) {
        that.StaFFList = res.data.data
      })   
    },
    Colse(){
      this.editVisible1 = false
      this.Row = {}
    },
    handleSelect(key){
      this.activeIndex = key
      this.isShow = key
    },
    init() {                          // 编辑器
      var that = this
      if (that.wangE == 20) {
        that.editor.destroy();
      } else {
          that.wangE = 0
      }
      setTimeout(function() {
        var Eat = that.Eattr
        console.log('!!!!!!!!!!!!!')
        const editor = new wangEditor(`#demo1`)
        Eat = editor
        that.wangE = 20
        editor.config.uploadImgHeaders = {token:that.heads.token}
        editor.config.onchange = (newHtml) => {that.editorData = newHtml}
        editor.config.showLinkImg = false  //关闭网络路径图片方式
        editor.config.uploadImgServer = 'https://rest-api.duxin365.com/admin/ajax/editorUpload'  // 上传图片到服务器
        editor.config.uploadFileName = 'file'
        editor.config.uploadImgTimeout = 50000;
        editor.config.uploadImgHooks = {
          fail: function (xhr, editor, result) {
            console.log(result)
          },
          success: function (xhr, editor, result) {
            console.log(result, 'success')
          }
        }
        editor.create()
        editor.txt.html(that.editorData) // 重新设置编辑器内容
        that.editor = editor
      },200)
    }, 
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
  },
};
</script>

<style scoped>  
.Redad{
  display: inline-block;
  width: 5px;
  height: 5px;
  background: #00CAB6;
  border: 1px solid #00CAB6;
  border-radius: 50%;
  margin-right: 8px;
}
.Redad1{
  display: inline-block;
  width: 5px;
  height: 5px;
  background: red;
  border: 1px solid red;
  border-radius: 50%;
  margin-right: 8px;
}
</style>
