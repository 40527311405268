
<template>
  <div class="Family_C">
    <div>
      <div class="Tables Xinj">
        <div class="xinjian">新建</div>
        <div class="X43">
          <div class="x2" @click="XinJian">
            <el-image
              style="width: 14px; height: 14px"
              :src="url"
              fit="fit"></el-image>
              <span>新建文档</span>
          </div>
          <div class="x2">
            <el-image
              style="width: 14px; height: 14px"
              :src="url"
              fit="fit"></el-image>
              <span>新建表格</span>
          </div>
          <div class="x2">
            <el-image
              style="width: 14px; height: 14px"
              :src="url"
              fit="fit"></el-image>
              <span>新建收集表</span>
          </div>
        </div>
        <div class="X41">
            <el-image
              style="width: 90px; height: 90px"
              :src="url"
              fit="fit"></el-image>
            <span>工作简报</span>
        </div>
      </div>

      <div class="Tables">
        <div class="xinjian">快速访问</div>
        <div style="overflow:hidden">
          <div class="C3">
            <el-image
              style="width: 36px; height: 36px"
              :src="url"
              fit="fit"></el-image>
              <span>新建文档</span>
          </div>
          <div class="C3">
            <el-image 
              style="width: 36px; height: 36px"
              :src="url"
              fit="fit"></el-image>
              <span>新建文档</span>
          </div>   
        </div>

        <div class="xinjian" style="margin-top:26px">最近</div>
        <div class="XinJian" style="margin-top:20px"></div>   
          <el-table
            :data="FileList"  
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange">
            <el-table-column align="center" label="公告名称">
                <template #default="scope" >
                  <img class="Toper" :src="scope.row.file_url" alt="">
                </template>
            </el-table-column>
            <el-table-column align="center" prop="name" label="名称"></el-table-column>
            <el-table-column align="center" prop="staff.realname" label="更新者"></el-table-column>
            <el-table-column align="center" prop="filesize" label="大小"></el-table-column>
            <el-table-column align="center" prop="createtime" label="最近更新"></el-table-column>
          </el-table>     
      </div>
    </div>


  </div>
</template>

<script> 
export default {
  name: "basetable",
  data() {
    return {
      radio:'1',
      show1:false,
      editVisible1:true,

      input: "",
      query: {
          address: "",
          name: "",
          pageIndex: 1,
          pageSize: 10
      },
      multipleSelection: [],
      delList: [],
      editVisible: true,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,

      type:'',
      template_id: 0,
    };
  },
  methods: {
    Add_kq() {
        console.log('re')
    },
    XinJian() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/disk/create', 
        data: {
          type:that.type,
          template_id:that.template_id
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total1 = res.data.data.total
      })   
    },

    // 删除操作
    XinJian(index) {
        // 二次确认删除
        this.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        })
            .then(() => {
                this.$message.success("删除成功");
                this.tableData.splice(index, 1);
            })
            .catch(() => {});
    },

  },
};
</script>
<style scoped> 
.Family_C .el-table__body, .el-table__footer, .el-table__header{
  width: 100% !important;
}
.Family_C .Xinj {
  padding-bottom: 12px;
}
.Family_C .xinjian {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #595959;
  margin-bottom: 20px;
}
.Family_C .Xinj .x2 {
  width: 119px;
  height: 32px;
  background: #FAFAFA;
  border-radius: 4px;
  padding: 4px 12px;
  box-sizing: border-box;
  margin-bottom: 14px;
}
.Family_C .Xinj .x2 span {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
  padding-left: 5px;
}
.Family_C .Xinj .x2 .el-image {
  float: left;
  margin-top: 5px;
}
.Family_C .X43 {
  float: left;
}
.Family_C .X41 {
  float: left;
  margin-left: 40px;
}
.Family_C .X41 span {
  display: block;
  text-align: center;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
  margin-top: 10px;
}
.Family_C .C3 {
  width: 232px;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  padding: 12px 24px;
  box-sizing: border-box;
  float: left;
  margin-right: 12px;
}
.Family_C .C3 span {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
  float: left;
  margin: 10px 0 0 14px;
}
.Family_C .C3 .el-image {
  float: left; 
}    
</style>
